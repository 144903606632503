import { createApp } from 'vue'
import App from './App.vue'
import {createPinia} from 'pinia'
const pinia = createPinia()
import router from './router'
import i18n from './i18n'
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import noData from '@/components/noData'

import { Popup, Swipe, SwipeItem,Checkbox,Overlay,Loading,Stepper } from 'vant';
import 'vant/lib/index.css';
import '@vant/touch-emulator'

import Notify from  '@/components/Notify'

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}


app.component('noData', noData)
app.use(pinia)
app.use(router)
app.use(i18n)
app.use(ElementPlus)
app.use(Popup);
app.use(Swipe);
app.use(SwipeItem);
app.use(Checkbox);
app.use(Overlay);
app.use(Loading);
app.use(Stepper)
app.component('Notify',Notify)
app.mount('#app')
