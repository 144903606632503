const zh = {
    alert:{
        reLogin: '地址发生改变，请重新登录',
        copied: '复制成功',
        connectWallet: '请连接钱包',
        notBound: '未绑定推荐人地址，请绑定',
        inputParent: '请输入推荐人地址',
        addressError: '请输入正确的钱包地址',
        parentError: '推荐人地址错误，请重新输入',
        boundSuccess: '绑定成功',
        boundFailed: '绑定失败，请重试',
        success: '成功',
        failed: '失败',
        soon: '即将开放...',
        insufficient: '余额不足',
        quotaInsufficient: '额度不足',
        amountError: '金额错误',
        minAmountError: '最低',
        redeemed: '订单不存在或已经赎回',
        enter: '确定',
        congratulation: '恭喜您合成',
        walletInsufficient: '钱包余额不足',
        copySuccess: '复制成功'
    },
    header: {
        link: '连接钱包',
        home: '首页',
        lp: '质押',
        award: '奖励',
        top: 'ATI',
        user: '我的',
        ido: 'IDO',
        inc: '© 2023 Arctic Intelligence Inc. 保留所有权利。',
        bind: '绑定',
        bindPla: '请输入邀请人地址',
        bindTitle: '请绑定邀请人地址'
    },
    home:{
        launchTitle: '赚取更多',
        launchDesc: 'Arctic Intelligence 是一个文化联盟，旨在传承人类文明。 通过创建全方面的AI生态系统为平台及其参与者提供多种人工智能服务，并创造ATI令牌的大规模利用。包括DeFi、机器人、生物技术和医疗、游戏和媒体、艺术和娱乐(音乐)以及企业级人工智能等。',
        but1: '质押',
        but2: '白皮书',
        nft1: '超级AI集成供应商',
        nft2: 'Arctic Intelligence旨在构建一个AI交易平台， 通过寻找最大利润的流动性挖矿、各大平台AI 合约及差价交易，和为工程师提供发展的平台， 实现产品的不断升级并为投资者提供可持续的回报!',
        nftbut: '了解更多',
        token: 'ATI代币',
        tokenBuy: '购买',
        tokenDesc: 'Arctic Intelligence Token(ATI)将作为整个平台的功能代币，可参与体验集成AI服务和社区治理，在未来将极具价值。',
        price: '价格',
        market: '市值',
        tokenAll: '总供应量',
        handelNftTitle: '为AI设计师提供平等发展机会',
        handelNftDesc: 'Arctic Intelligence的核心业务之一是为不同的市场提供一个符合各个市场需求的顶尖的AI模型。让有 才华而缺少资金的工程师们发挥自己的才华，让他能够在他提供才华与经验之时能够得到所应得的回报，这 样既改善了Arctic Intelligence自身的AI模型，又给市场提供了更好的产品从而获得更高的收益回报',
        handelArtTitle: 'AI智能平台',
        handelArtDesc: 'Arctic Intelligence承诺将百分之三十的收入用于搭建AI智能平台。通过人工智能算法库进行搜索、试 用和选择。搭建更有选择性的智能平台。 这不仅让工程师获得必要的支持，还将AI模型的所有权归属于 Arctic Intelligence公司。这些作品将由Arctic Intelligence和我们的合作伙伴进行销售和发展，从而实 现更广泛的推广和销售，同时确保工程师得到公平的回报',
        handelAiTitle: 'AI交易',
        handelAiDesc: '百分之三十的资金将投入到AI交易中，以实现可持续的盈利。我们的AI交易策略将应用先进的算法和数据分析技术。这将帮助我们最大程度地增加资金，并提供稳定的财务回报，以支持项目的可持续性和未来发展。',
        friend: '合作伙伴',
        workTitle: '在Arctic Intelligence工作',
        workDesc: '我们一直在寻找最优秀的人才。请参阅下面的空缺职位。'
    },
    lp:{
        stackDesc: 'Arctic Intelligence将USDT质押变得更简单，为投资者和社区成员提供持续的价值和可观的回报。',
        stackList1: '我的质押',
        stackList2: '已赚取',
        stackList3: '已复利获得',
        lp: '质押',
        underway: '质押中',
        earn: '已赚取收益',
        yet: '可提取收益',
        redeem: '赎回',
        yetBut: '提取',
        accredit: '授权',
        record: '质押记录',
        tokenTitle: '复利收益',
        tokenDesc: '不提取质押产生的收益将产生复利收益，按1ATI = 0.1USDT配发ATI代币。Arctic Intelligence Token(ATI)将作为整个平台的功能代币，可参与体验集成AI服务和社区治理，在未来将极具价值。',
        tokenList1: '是否可享受复利',
        tokenList2: '已获得复利收益',
        tokenList3: '可提取复利收益',
        yes: '是',
        no: '否',
        back: '返回质押',
        money: '金额',
        timeStart: '质押时间',
        timeEnd: '到期时间',
        cancel: '取消自动续期',
        more: '更多...',
        max: '最大',
        quotaRemain: '余额',
        usable: '可质押'
    },
    award:{
        communityDesc: '为社区发展做出贡献的社区成员，将获得社区专项奖励。',
        communityList1: '已获取奖励',
        communityList2: '已提取',
        communityList3: '可提取',
        communityBut: '提取',
        record: '奖励记录',
        listType1: '直推奖励',
        listType2: '团队奖励',
        listType3: '平级奖励',
        listType4: '分红奖励'
    },
    top:{
        tokenDesc: 'Arctic Intelligence Token(ATI)将作为整个平台的功能代币，可参与体验集成AI服务和社区治理，在未来将极具价值。',
        price: '价格',
        allAmount: '总供应量',
        market: '市值',
        my: '我的ATI',
        balance: '钱包余额',
        buy: 'Swap',
        approve: '批准USDT',
        approveTop: '批准ATI'
    },
    ido:{
        turn: '当前轮次',
        all: 'IDO总量',
        price: '价格',
        limit: '认购额度',
        ps: '解锁方式：锁定[]个月后，分12个月线性解锁',
        my: '我的额度',
        nobuy: '未认购',
        balance: '余额',
        custom: '预计获得',
        buy: '立即认购',
        buyYet: '已认购',
        record: '认购记录',
        buyTrun: '认购轮次',
        buyUsdt: '认购USDT',
        getTop: '获得ATI',
        unlock: '下一次解锁时间',
        unlockYet: '已解锁ATI',
        claimable: '可领取ATI',
        get: '领取',
        nodataText: '暂无认购记录'
    },
    user:{
        desc: '分享Arctic Intelligence，帮助更多需要帮助的人们，在区块链领域赚取可观收益。',
        joinTime: '注册时间',
        memberTeam: '团队成员',
        memberDirect: '直推成员',
        teamLp: '团队质押',
        myLink: '我的邀请链接',
        myTeam: '我的团队',
        personage: '个人质押',
        cur: '当前',
        lookDown: '查看下级',
        copy: '复制',
        info: '个人信息'
    }
}

export default zh;
