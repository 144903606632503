const zh = {
    alert:{
        reLogin: '地址發生改變，請重新登錄',
        copied: '復製成功',
        connectWallet: '請連接錢包',
        notBound: '未綁定推薦人地址，請綁定',
        inputParent: '請輸入推薦人地址',
        addressError: '請輸入正確的錢包地址',
        parentError: '推薦人地址錯誤，請重新輸入',
        boundSuccess: '綁定成功',
        boundFailed: '綁定失敗，請重試',
        success: '成功',
        failed: '失敗',
        soon: '即將開放...',
        insufficient: '余額不足',
        quotaInsufficient: '額度不足',
        amountError: '金額錯誤',
        minAmountError: '最低',
        redeemed: '訂單不存在或已經贖回',
        enter: '確定',
        congratulation: '恭喜您合成',
        walletInsufficient: '錢包余額不足',
        copySuccess: '復製成功'
    },
    header: {
        link: '連接錢包',
        home: '首頁',
        lp: '質押',
        award: '獎勵',
        top: 'ATI',
        user: '我的',
        ido: 'IDO',
        inc: '© 2023 Arctic Intelligence Inc. 保留所有權利。',
        bind: '綁定',
        bindPla: '請輸入邀請人地址',
        bindTitle: '請綁定邀請人地址'
    },
    home:{
        launchTitle: '賺取更多',
        launchDesc: 'Arctic Intelligence 是一個文化聯盟，旨在傳承人類文明。 通過創建全方面的AI生態系統為平臺及其參與者提供多種人工智能服務，並創造ATI令牌的大規模利用。包括DeFi、機器人、生物技術和醫療、遊戲和媒體、藝術和娛樂(音樂)以及企業級人工智能等。',
        but1: '質押',
        but2: '白皮書',
        nft1: '超級AI集成供應商',
        nft2: 'Arctic Intelligence旨在構建一個AI交易平臺， 通過尋找最大利潤的流動性挖礦、各大平臺AI 合約及差價交易，和為工程師提供發展的平臺， 實現產品的不斷升級並為投資者提供可持續的回報!',
        nftbut: '了解更多',
        token: 'ATI代幣',
        tokenBuy: '購買',
        tokenDesc: 'Arctic Intelligence Token(ATI)將作為整個平臺的功能代幣，可參與體驗集成AI服務和社區治理，在未來將極具價值。',
        price: '價格',
        market: '市值',
        tokenAll: '總供應量',
        handelNftTitle: '為AI設計師提供平等發展機會',
        handelNftDesc: 'Arctic Intelligence的核心業務之一是為不同的市場提供一個符合各個市場需求的頂尖的AI模型。讓有 才華而缺少資金的工程師們發揮自己的才華，讓他能夠在他提供才華與經驗之時能夠得到所應得的回報，這 樣既改善了Arctic Intelligence自身的AI模型，又給市場提供了更好的產品從而獲得更高的收益回報',
        handelArtTitle: 'AI智能平臺',
        handelArtDesc: 'Arctic Intelligence承諾將百分之三十的收入用於搭建AI智能平臺。通過人工智能算法庫進行搜索、試 用和選擇。搭建更有選擇性的智能平臺。 這不僅讓工程師獲得必要的支持，還將AI模型的所有權歸屬於 Arctic Intelligence公司。這些作品將由Arctic Intelligence和我們的合作夥伴進行銷售和發展，從而實 現更廣泛的推廣和銷售，同時確保工程師得到公平的回報',
        handelAiTitle: 'AI交易',
        handelAiDesc: '百分之三十的資金將投入到AI交易中，以實現可持續的盈利。我們的AI交易策略將應用先進的算法和數據分析技術。這將幫助我們最大程度地增加資金，並提供穩定的財務回報，以支持項目的可持續性和未來發展。',
        friend: '合作夥伴',
        workTitle: '在Arctic Intelligence工作',
        workDesc: '我們一直在尋找最優秀的人才。請參閱下面的空缺職位。'
    },
    lp:{
        stackDesc: 'Arctic Intelligence將USDT質押變得更簡單，為投資者和社區成員提供持續的價值和可觀的回報。',
        stackList1: '我的質押',
        stackList2: '已賺取',
        stackList3: '已復利獲得',
        lp: '質押',
        underway: '質押中',
        earn: '已賺取收益',
        yet: '可提取收益',
        redeem: '贖回',
        yetBut: '提取',
        accredit: '授權',
        record: '質押記錄',
        tokenTitle: '復利收益',
        tokenDesc: '不提取質押產生的收益將產生復利收益，按1ATI = 0.1USDT配發TOP代幣。Arctic Intelligence Token(ATI)將作為整個平臺的功能代幣，可參與體驗集成AI服務和社區治理，在未來將極具價值。',
        tokenList1: '是否可享受復利',
        tokenList2: '已獲得復利收益',
        tokenList3: '可提取復利收益',
        yes: '是',
        no: '否',
        back: '返回質押',
        money: '金額',
        timeStart: '質押時間',
        timeEnd: '到期時間',
        cancel: '取消自動續期',
        more: '更多...',
        max: '最大',
        quotaRemain: '余額',
        usable: '可質押'
    },
    award:{
        communityDesc: '為社區發展做出貢獻的社區成員，將獲得社區專項獎勵。',
        communityList1: '已獲取獎勵',
        communityList2: '已提取',
        communityList3: '可提取',
        communityBut: '提取',
        record: '獎勵記錄',
        listType1: '直推獎勵',
        listType2: '團隊獎勵',
        listType3: '平級獎勵',
        listType4: '分紅獎勵'
    },
    top:{
        tokenDesc: 'Arctic Intelligence Token(ATI)將作為整個平臺的功能代幣，可參與體驗集成AI服務和社區治理，在未來將極具價值。',
        price: '價格',
        allAmount: '總供應量',
        market: '市值',
        my: '我的ATI',
        balance: '錢包余額',
        buy: 'Swap',
        approve: '批準USDT',
        approveTop: '批準ATI'
    },
    ido:{
        turn: '當前輪次',
        all: 'IDO總量',
        price: '價格',
        limit: '認購額度',
        ps: '解鎖方式：鎖定三個月後，分12個月線性解鎖',
        my: '我的額度',
        nobuy: '未認購',
        balance: '余額',
        custom: '預計獲得',
        buy: '立即認購',
        buyYet: '已認購',
        record: '認購記錄',
        buyTrun: '認購輪次',
        buyUsdt: '認購USDT',
        getTop: '獲得TOP',
        unlock: '下一次解鎖時間',
        unlockYet: '已解鎖Top',
        claimable: '可領取TOP',
        get: '領取',
        nodataText: '暫無認購記錄'
    },
    user:{
        desc: '分享Arctic Intelligence，幫助更多需要幫助的人們，在區塊鏈領域賺取可觀收益。',
        joinTime: '註冊時間',
        memberTeam: '團隊成員',
        memberDirect: '直推成員',
        teamLp: '團隊質押',
        myLink: '我的邀請鏈接',
        myTeam: '我的團隊',
        personage: '個人質押',
        cur: '當前',
        lookDown: '查看下級',
        copy: '復製',
        info: '個人信息'
    }
}

export default zh;
