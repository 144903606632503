const zh = {
    alert:{
        reLogin: 'The address has changed, please log in again',
        copied: 'Copied',
        connectWallet: 'Please connect wallet',
        notBound: 'The recommender address is not bound, please bind it.',
        inputParent: 'Please enter the recommender\'s address',
        addressError: 'Please enter the correct wallet address',
        parentError: 'The recommender\'s address is wrong, please re-enter it.',
        boundSuccess: 'Binding successful',
        boundFailed: 'Binding failed, please try again',
        success: 'Success',
        failed: 'Failed',
        soon: 'Opening soon...',
        insufficient: 'Insufficient balance',
        quotaInsufficient: 'Insufficient quota',
        amountError: 'Wrong amount',
        minAmountError: 'Lowest',
        redeemed: 'The order does not exist or has been redeemed',
        enter: 'Sure',
        congratulation: 'Congratulations on your synthesis',
        walletInsufficient: 'Insufficient wallet balance',
        copySuccess: 'Copied successfully'
    },
    header: {
        link: 'Connect wallet',
        home: 'Home',
        lp: 'Stake',
        award: 'Award',
        top: 'ATI',
        user: 'Mine',
        ido: 'IDO',
        inc: '© 2023 Arctic Intelligence Inc. All rights reserved.',
        bind: 'Binding',
        bindPla: 'Please enter the inviter\'s address',
        bindTitle: 'Please bind the inviter’s address'
    },
    home:{
        launchTitle: 'Earn more',
        launchDesc: 'Arctic Intelligence is a cultural alliance aimed at inheriting human civilization. By creating a comprehensive AI ecosystem to provide multiple artificial intelligence services to its platform participants and create large-scale utilization of ATI tokens. Including DeFi, robotics, biotechnology and medical care, games and media, arts and entertainment (music), and enterprise-level artificial intelligence, etc.',
        but1: 'Stake',
        but2: 'Whitepaper',
        nft1: 'Super AI integration provider',
        nft2: 'Arctic Intelligence aims to build an AI trading platform that achieves continuous product upgrades and provides investors with sustainable returns by finding the most profitable liquidity mining, AI contracts and spread trading on major platforms, and providing a development platform for engineers!',
        nftbut: 'Learn more',
        token: 'ATI token',
        tokenBuy: 'Buy',
        tokenDesc: 'Arctic Intelligence Token (ATI) is the functional currency that powers our entire platform. With ATI, Can participate in and experience integrated AI services and community governance, and unlock a world of valuable opportunities. Don\'t miss out on the immense future value of ATI as it becomes an essential asset in the art, financial and ESG market place.',
        price: 'Price',
        market: 'Market value',
        tokenAll: 'Total supply',
        handelNftTitle: 'Provide equal development opportunities for AI designers',
        handelNftDesc: "One of Arctic Intelligence's core businesses is to provide different markets with a top-notch AI model that meets the needs of each market. Let talented engineers who lack funds use their talents so that they can get the rewards they deserve when they provide their talents and experience. This not only improves Arctic Intelligence's own AI model, but also provides the market with more information. Good products lead to higher returns",
        handelArtTitle: 'AI intelligent platform',
        handelArtDesc: 'Arctic Intelligence promises to use 30% of its revenue to build an AI intelligence platform. Search, try and select from a library of artificial intelligence algorithms. Build a more selective intelligent platform. This not only gives engineers the necessary support, but also gives ownership of the AI ​​model to Arctic Intelligence. These titles will be marketed and developed by Arctic Intelligence and our partners, enabling wider promotion and sales while ensuring engineers are fairly compensated',
        handelAiTitle: 'Harnessing the Power of AI Trading',
        handelAiDesc: "Thirty percent of the funds will be invested in AI trading to achieve sustainable profits. Our AI trading strategies will apply advanced algorithms and data analysis technology. This will help us maximize funding and provide stable financial returns to support the sustainability and future development of the project.",
        friend: 'Partner',
        workTitle: 'Work at Arctic Intelligence',
        workDesc: 'We are always looking for the best talent. See open positions below.'
    },
    lp:{
        stackDesc: 'Arctic Intelligence makes USDT staking easier, providing investors and community members with ongoing value and substantial returns.',
        stackList1: 'My Stake',
        stackList2: 'Earned',
        stackList3: 'Earned with compound interest',
        lp: 'Stake',
        underway: 'Stake in progress',
        earn: 'Earned revenue',
        yet: 'Withdrawable earnings',
        redeem: 'Redeem',
        yetBut: 'Withdraw',
        accredit: 'Authorize',
        record: 'Stake record',
        tokenTitle: 'compound interest income',
        tokenDesc: 'The income generated by not withdrawing the pledge will generate compound interest income, and ATI tokens will be distributed according to 1ATI = 0.1USDT. Arctic Intelligence Token (ATI) will serve as the functional token of the entire platform, which can participate in the experience of integrated AI services and community governance, and will be extremely valuable in the future.',
        tokenList1: 'Whether compound interest can be enjoyed',
        tokenList2: 'Received compound interest returns',
        tokenList3: 'Withdrawable compound interest income',
        yes: 'Yes',
        no: 'No',
        back: 'Return',
        money: 'Amount',
        timeStart: 'Stake time',
        timeEnd: 'Expire date',
        cancel: 'Cancel automatic renewal',
        more: 'More...',
        max: 'Max',
        quotaRemain: 'Balance',
        usable: 'Stakeable'
    },
    award:{
        communityDesc: 'Community members who contribute to community development will receive special community rewards.',
        communityList1: 'Received reward',
        communityList2: 'Extracted',
        communityList3: 'Extractable',
        communityBut: 'Withdraw',
        record: 'Award record',
        listType1: 'Direct rewards',
        listType2: 'Team rewards',
        listType3: 'Level rewards',
        listType4: 'Dividend rewards'
    },
    top:{
        tokenDesc: 'Arctic Intelligence Token (ATI) will serve as the functional token of the entire platform, allowing users to participate in integrated AI services and community governance, and will be extremely valuable in the future.',
        price: 'price',
        allAmount: 'total supply',
        market: 'Market value',
        my: 'My ATI',
        balance: 'wallet balance',
        buy: 'Swap',
        approve: 'Approve USDT',
        approveTop: 'Approve ATI'
    },
    ido:{
        turn: 'Current round',
        all: 'IDO Supply',
        price: 'Price',
        limit: 'Quota',
        ps: 'Unlocking method: After locking for [] months, linearly unlock in 12 months',
        my: 'My Quota',
        nobuy: 'Unsubscribed',
        balance: 'Balance',
        custom: 'Expected',
        buy: 'Subscribe',
        buyYet: 'Subscribed',
        record: 'Records',
        buyTrun: 'Subscription round',
        buyUsdt: 'Pay USDT',
        getTop: 'Obtain ATI',
        unlock: 'Next unlocking time',
        unlockYet: 'Unlocked',
        claimable: 'Can claim ATI',
        get: 'Claim',
        nodataText: 'No records'
    },
    user:{
        desc: 'Share Arctic Intelligence, help more people in need, and earn considerable profits in the blockchain field.',
        joinTime: 'Reg time',
        memberTeam: 'Team',
        memberDirect: 'Directly',
        teamLp: 'Team staking',
        myLink: 'My invitation link',
        myTeam: 'My team',
        personage: 'Personal Stake',
        cur: 'Current',
        lookDown: 'Subordinates',
        copy: 'copy',
        info: 'personal details'
    }
}

export default zh;
