<template>
    <Teleport to="#app">
        <div class="n-root" v-if="showNotify" >
            <div>
                <span>{{ $t('alert.' + title) }}</span>
            </div>
        </div>
    </Teleport>
</template>
<script setup>
    import { ref, defineProps, toRef, watch,defineExpose } from 'vue';

    const showNotify = ref(false);
    const props = defineProps({
        title: String
    })
    const title = toRef(props, 'title')

    const isShow = () =>{
        if(!showNotify.value){
            showNotify.value = true;
            setTimeout(() => {
               showNotify.value = false
            }, 1000);
        }
    }
    defineExpose({  
        isShow
    })
</script>
<style scoped>
    .n-root{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        color: white;
        z-index: 1000000;
    }
    .n-root div{
        width: 100%;
        position: absolute;
        bottom: 40px;
        margin: 0 auto;
        text-align: center;
    }
    .n-root div span{
        padding: 8px 20px;
        border-radius: 60px;
        background: rgba(81, 81, 81, 0.6); 
    }
</style>